import { observable, action, decorate } from 'mobx'

class NavModel {
  open = false
  selectedProjects = false
  showreels = false
  archivedProjects = false
  activeSection = false
  splashOpen = true // true
  navHover = false

  openNav() {
    this.open = true
  }

  closeNav() {
    this.open = false
    this.activeSection = false
    this.navHover = false
  }

  openProjectIndex() {
    this.open = true
    this.activeSection = 'projects'
  }

  hideSplash() {
    this.splashOpen = false
  }

  showNavHover() {
    this.navHover = true
  }

  hideNavHover() {
    this.navHover = false
  }

  init({ selectedProjects, archivedProjects, showreels }) {
    this.selectedProjects = this.sortIntoClients(selectedProjects)
    this.archivedProjects = this.sortIntoClients(archivedProjects)
    this.showreels = this.sortIntoClients(showreels)
  }

  sortIntoClients(projects) {
    const projectsIntoClients = []

    projects.forEach(project => {
      if (project.client) {
        const existingClient = projectsIntoClients.find(client =>
          project.client.name === client.name
        )

        if (existingClient) {
          existingClient.projects.push(project)
        } else {
          projectsIntoClients.push({
            id: project.client.id,
            name: project.client.name,
            slug: project.client.slug,
            projects: [project]
          })
        }
      }
    })

    return projectsIntoClients
  }

  setSection(section) {
    this.activeSection = section
  }
}

decorate(NavModel, {
  open: observable,
  selectedProjects: observable,
  showreels: observable,
  archivedProjects: observable,
  activeSection: observable,
  splashOpen: observable,
  navHover: observable,
  openNav: action,
  closeNav: action,
  hideSplash: action,
  init: action,
  setSection: action,
  openProjectIndex: action
})

const NavStore = new NavModel()
export default NavStore
