import { observable, action, decorate } from 'mobx'

class ProjectModel {
  projects = []
  project = false
  path = false
  videoIndex = 0
  activeTitle = false
  videoMode = false
  audible = false
  previouslyAudible = false
  infoOpen = false
  videoButtonHover = false
  infoButtonHover = false
  loading = false
  isTouch = false
  playBlocked = false

  init({ projects, path = 'projects' }) {
    let newProjects = projects.map(p => p.node ? p.node : p)
    this.projects = newProjects
    this.project = this.projects[0]
    this.activeTitle = this.project.videos[0].title
    this.path = path
  }

  toggleVideoMode() {
    this.videoMode = !this.videoMode

    if (this.videoMode) {
      this.previouslyAudible = this.audible
      this.audible = true
    } else {
      // When exiting video mode, if state of audio before going
      // into video mode was on but it was turned off in video videoMode
      // keep it off when exiting video mode
      if (this.previouslyAudible && !this.audible) {
        this.audible = false
      } else {
        this.audible = this.previouslyAudible
      }
    }
  }

  toggleAudio() {
    this.audible = !this.audible
  }

  toggleInfo() {
    this.infoOpen = !this.infoOpen
  }

  closeInfo() {
    this.infoOpen = false
  }

  setTouch(touch) {
    this.isTouch = touch
  }

  updateActiveProject(id) {
    const newProject = this.projects.find(p => p.id === id)
    this.project = newProject
    this.videoMode = false
    this.audible = false
    this.infoOpen = false
    this.updateActiveVideo()
  }

  updateActiveVideo(index = 0) {
    this.activeTitle = this.project.videos[index].title
    this.videoIndex = index
  }

  showVideoButtonHover() {
    this.videoButtonHover = true
  }

  hideVideoButtonHover() {
    this.videoButtonHover = false
  }

  showInfoButtonHover() {
    this.infoButtonHover = true
  }

  hideInfoButtonHover() {
    this.infoButtonHover = false
  }

  showLoading() {
    this.loading = true
  }

  hideLoading() {
    this.loading = false
  }

  videoPlayBlocked() {
    this.playBlocked = true
    this.loading = false
  }

  videoPlayUnblocked() {
    this.playBlocked = false
  }

  forcePlay() {
    this.audible = true
    this.loading = false
    this.playBlocked = false
  }
}

decorate(ProjectModel, {
  projects: observable,
  project: observable,
  videoIndex: observable,
  activeTitle: observable,
  videoMode: observable,
  audible: observable,
  infoOpen: observable,
  videoButtonHover: observable,
  infoButtonHover: observable,
  loading: observable,
  isTouch: observable,
  playBlocked: observable,
  init: action,
  goToProject: action,
  toggleInfo: action,
  closeInfo: action,
  setTouch: action,
  toggleVideoMode: action,
  exitVideoMode: action,
  toggleAudio: action,
  showVideoButtonHover: action,
  hideVideoButtonHover: action,
  showInfoButtonHover: action,
  hideInfoButtonHover: action,
  showLoading: action,
  hideLoading: action,
  videoPlayBlocked: action,
  videoPlayUnblocked: action,
  forcePlay: action
})

const ProjectStore = new ProjectModel()
export default ProjectStore
