import React from "react"
import { Provider } from "mobx-react"
import ProjectStore from "./src/stores/project-store"
import NavStore from "./src/stores/nav-store"

export default ({ element }) => (
  <Provider
    projectStore={ProjectStore}
    navStore={NavStore}
  >
    {element}
  </Provider>
)
